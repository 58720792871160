import { updateVar, popupsVariables, init as popupsInit, close as popupsClose, loader, confirm } from "./popups.js"
import { default as recaptchaInit, submit as recaptchaSubmit } from "./recaptcha.js";
import { cookieValue, setCookieValue, isMobileDevice } from "./utilities.js";
import { page as analyticsPage } from "./analytics.js";

const cookieName = "stEf";
const url = '/exit-form';
const cookieExpiryDays = 30;

const mobileHandler = () => {
    const pageCountCookieName = "efPageCount";
    const pageCountExpiryDays = 1 / 24;
    const pageCountCookie = cookieValue(pageCountCookieName);

    const setPageCount = () => {
        setCookieValue(pageCountCookieName, 1, pageCountExpiryDays);
    }
    if (!pageCountCookie) {
        setPageCount(1);
        return;
    }

    const pageCount = parseInt(pageCountCookie) + 1;
    setPageCount(pageCount);

    if (pageCount < 2) {
        return;
    }

    const bodyElement = document.querySelector('body');
    const scrollPercentage = (window.scrollY / bodyElement.offsetHeight) * 100;
    if (scrollPercentage > 70) {
        shouldShowForm(() => {
            showForm();
            didShowForm();
        });

        setPageCount(0);
    }
}

const desktopHandler = () => {
    let mouseIsOffScreen = false;
    window.onmousemove = (e) => {
        if (e.clientY < 15 && !mouseIsOffScreen) {
            mouseIsOffScreen = true;
            setTimeout(checkMousePosition, 1500);
        }
        else {
            mouseIsOffScreen = false;
        }
    }

    const checkMousePosition = () => {
        if (!mouseIsOffScreen)
            return;

        shouldShowForm(() => {
            showForm();
            didShowForm();
        });
    }
}

const showForm = () => {
    if (!popupsVariables['allow'] || window.site.win.width() < popupsVariables['limit'] || popupsVariables['backTo']) {
        location.href = url;
        return false;
    }

    // check if current url
    var comp = url;
    var path = location.pathname;

    // remove comp slash
    if (comp.substr(-1) == '/') {
        comp = comp.substr(0, comp.length - 1);
    }

    // remove path slash
    if (path.substr(-1) == '/') {
        path = path.substr(0, path.length - 1);
    }

    // redirect
    if (comp == path) {
        return false;
    }

    // set type
    updateVar('type', 'launch');
    // show loader
    loader();

    // define template
    var template = $('#popupLaunchTemplate').html();
    if (typeof template === 'undefined') {
        location.href = url;
        return;
    }

    // parse template
    template = Hogan.compile(template);

    // hide open popups
    var visible = $('.m-popup:visible');
    if (visible.length > 0) {
        // set backto
        updateVar('backTo', true);
        // set attr
        visible.attr('data-hidden', 'true').hide();
    }

    // get page content
    $.ajax({
        url: url,
        cache: false,
        success: function(response) {
            var content = $(response).find('#popupContentWrapper').html();
            if (typeof content === 'undefined') {
                location.href = url;
                return;
            }
            // render template
            var rendered = template.render({
                content: content,
                url: url
            });

            // add template to body
            window.site.body.append(rendered);
            init();
            // get popup
            var p = $('.m-popup[data-href="' + url + '"]');
            // set top
            popupsInit();
            // show
            p.add('#popupFade:hidden').show();
            // hide loader
            loader(true, true);
            updateVar('allow', true);
            updateVar('fadeClick', true);
            // analytics
            analyticsPage(url, 'Popup');

            window.updateDynamicPhoneNumber()

            updateVar('newsletterAllow', true);
        },
        error: function(e) {
            // hide loader
            loader(true);
            updateVar('allow', true);
            updateVar('fadeClick', true);
            // show notice
            confirm('Error', 'Sorry, the URL "' + url + '" could not be shown (' + e.statusText.toLowerCase() + ').', '', true);
            updateVar('newsletterAllow', true);
        }
    });
}

const shouldShowForm = (showFormAction) => {
    if (!cookieValue(cookieName))
        showFormAction();
}

const didShowForm = () => {
    setCookieValue(cookieName, true, cookieExpiryDays);
}


const setupFadeClick = () => {
    $('#popupFade').off('click', onFadeClick).on('click', onFadeClick);
}

const setupCloseClick = () => {
    $('.exit_form-close').off('click', onFadeClick).on('click', onFadeClick);
}

const setupHandlers = () => {
    setupFadeClick();
    setupCloseClick();
}

const setupFormSubmit = () => {
    const form = $('.exit_form form:not(.js-form-exit-done)');
    if (!form) {
        return;
    }

    const button = form.find(':submit');
    form.on('submit', () => {
        // reset validation
        form.removeData('validator');
        form.removeData('unobtrusiveValidation');
        // validate form
        $.validator.unobtrusive.parse(form);
        // check
        if (form.valid() && recaptchaSubmit) {
            button.addClass('disabled').prop('disabled', true);
            $.ajax({
                type: 'POST',
                url: form.attr('action'),
                data: form.serialize(),
                cache: false,
                success: function(response) {
                    // remove error
                    form.prev('.js-ajax-error:first').remove();
                    loader(true, true);
                    // check
                    if (response.Valid) {
                        onFadeClick();
                        confirm(
                            window.popupData.newsletter.success.title,
                            window.popupData.newsletter.success.message,
                            '',
                            true
                        );
                    }
                    else {
                        updateVar('allow', true);
                        // add error
                        form.before('<p class="form-error js-ajax-error">' + response.Message + '</p>');
                        setupFadeClick();
                    }
                },
                error: function() {
                    // remove error
                    form.prev('.js-ajax-error:first').remove();
                    // add error
                    form.before('<p class="form-error js-ajax-error">Sorry, there was an unexpected server error.</p>');
                    loader(true, true);
                    setupFadeClick();
                },
                complete: function() {
                    // enable button
                    button.removeClass('disabled').prop('disabled', false);
                }
            });

        }

        return false;
    });

    // done
    form.addClass('js-form-exit-done');
}

const onFadeClick = () => {
    popupsClose(false);
}


const init = () => {

    setupFormSubmit();
    setupHandlers();
    recaptchaInit();

    if (isMobileDevice()) {
        mobileHandler();
    } else {
        desktopHandler();
    }
}


export default init;
export { setupHandlers, setupFadeClick };

